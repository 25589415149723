import * as React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"
import BasicPage from "../components/basicPage"


const content = `
<em>Last updated on November 1, 2021</em>
 
<p>Thank you for using the Kosmique app. Kosmique respects your concerns about privacy. The Privacy Policy set forth herein (the “Policy”) applies to this app, including any related apps and websites contained or linked to this app (collectively, the “app”). </p>
 
<h2>Terms of Use and Consent</h2>
<p>The Policy is part of the Terms of Use that govern your use of the app. By accessing the app, you consent Kosmique’s collection, storage, analysis, use and disclosure of your information as set forth in the Policy. </p>
 
<p>We reserve the right, in our sole discretion, to change, modify, add or remove portions of the Policy at any time. Therefore, we suggest that you review the Policy periodically for changes. By accessing the app after we have posted changes to the Policy, you are agreeing to accept the Policy as amended. </p>
 
<h2>Information We Collect</h2>
<p>We collect, store, and use certain personal information through the app. The types of personal information we may collect include:
Contact information, such as your name, e-mail address, and telephone number;
Names, e-mail addresses and telephone numbers of others to whom you ask us to send information;
Username and password and your online profile for your user account;
Payment information (such as payment card details, financial account information and billing address);
Date of birth;
Information about your education, work and military history, legal work eligibility status, and other information relevant to specific jobs for which you may apply;
Information from third-party (social) media platforms;
Other personal information you may provide to us;
<p>In addition, when you visit the app, we may collect certain information by automated means, such as cookies, web beacons and geolocation information. The information we collect in this manner includes, but is not limited to, IP addresses, browser characteristics, device characteristics, operating system, language preferences, referring URLs, information on actions taken on the app, and the dates and times of visits to the app. A “cookie” is a text file that apps send to a visitor's computer or other Internet-connected device to uniquely identify the visitor's browser or to store information or settings in the browser. A “web beacon,” also known as an Internet tag, pixel tag or clear GIF, links apps to web servers and their cookies and may be used to transmit information collected through cookies back to a web server. Through these automated collection methods, we obtain "clickstream data," which is a log of content on which a visitor clicks while browsing a app. As the visitor clicks through the app, a record of the clicks may be collected and stored. We may link certain data elements we have collected through automated means, such as your browser information, with other information we have obtained about you to let us know. Your browser may notify you when you receive certain types of cookies or enable you to restrict or disable certain types of cookies; however, if you restrict or disable cookies you may not be able to use all of the features of the app.</p>
 
<p>If you use features of the app populated and/or operated by third-party social media platforms (such as Facebook, Twitter and/or Instagram), we may receive information you have made available to those platforms, including geographic location and other information about you (such as name, e-mail address, gender, biographical information, photo(s), etc.) and your platform contacts. Some of these social media platforms allow you to push content from the app to your contacts or to pull information about your contacts to the app. Social media platforms may also facilitate your registration for the app or enhance or personalize your experience on the app. You should make sure you independently understand the privacy policies and information your third-party social media platforms may make available to the app. We may use and disclose the personal information we receive on the app through third-party social media platforms as described in the Policy. </p>
 
<p>From time to time, we may obtain e-mail addresses from third party sources to be used for promotional e-mails. You may opt out of receiving any messages from us in the future by either clicking on the Unsubscribe link in the e-mail, or by contacting us as instructed in the How to Contact Us section of the Policy and requesting to opt out of receiving Kosmique related communications.
 </p>

<h2>Our Use of Information</h2>
We may use the information we obtain through the app to:
Create and manage your user account;
Offer and provide products and services to you;</h2>
<p>Communicate about, and administer your participation in, special events, programs, surveys, contests, and other offers or promotions;
Send information to others at your request;
Respond to and communicate with you about your requests, questions and comments;
Manage your application for employment with Kosmique and contact you in connection with potential employment opportunities;
Process claims we receive in connection with our products and services;
Operate, evaluate, and improve our business (including developing new products and services, managing our communications, determining the effectiveness of our sales, marketing and advertising, analyzing and enhancing our products, services and the app, and performing accounting, auditing, billing, reconciliation and collection activities);
Perform data analyses and other processing (including market and consumer research, trend analysis, financial analysis, anonymization, encryption, and tokenization of personal information);
Protect against, identify and prevent fraud and other criminal activity, claims and other liabilities; and
Comply with and enforce applicable legal requirements, relevant industry standards and our policies.
<p>In addition, we may use information collected online through automated means, including, but not limited to cookies, local shared objects (also known as “Flash cookies”) and web beacons, for purposes such as (i) customizing our users’ visits to the app, (ii) delivering content (including advertising) tailored to our users’ interests and the manner in which our users browse the app, (iii) marketing, (iv) demographic research and (v) managing our business. We may supplement data we collect through automated means with information about your location (such as your zip code) to provide you with content that may be of interest to you. We also use this information to help diagnose technical and service problems, administer the app, identify users of the app, and gather demographic information about our users. We use clickstream data to determine how much time users spend on certain pages on the app, how users navigate through the app, and how we may tailor the app to better meet the needs of our users.</p>
 
<h2>Internet-Based Advertising</h2>
<p>On the app, we may collect information about your online activities for use in providing you with advertising about products and services tailored to your individual interests. You may see certain advertisements on other apps based on your visits to the app because we participate in advertising networks administered by third-party vendors. Advertising networks allow us to target our messaging to users through demographic, interest-based and contextual means. These advertising networks track your online activities over time by collecting information through automated means, including, but not limited to, through the use of cookies and web beacons. The advertising networks use this information to show you advertisements that are tailored to your individual interests. The information our advertising network vendors collect includes information about your visits to apps that participate in the vendors' advertising networks, such as the pages or advertisements you view and the actions you take on the apps. This data collection takes place both on the app and on third-party apps that participate in the advertising networks. This process also helps us track the effectiveness of our marketing efforts.
 
<h2>Information We Share</h2>
<p>We do not sell or otherwise share personal information about you, except as described in the Policy. When you post a comment on the app, third-party social media platforms or blogs, we may publish your name and/or user ID with your comment. We share personal information with third parties who perform services on our behalf based on our instructions. These third parties are not authorized by us to use or disclose the information except as necessary to perform services on our behalf or comply with legal requirements. We also may share the personal information we obtain with our affiliates, franchisees and joint marketing partners.
 
<h2>Legal Matters</h2>
<p>We may disclose information you have provided to us if we have a good faith belief that such disclosure is necessary to: (i) comply with the law, government action or with legal process, (ii) protect and defend our rights or property, including, but not limited to, the app, or (iii) protect the personal safety or property of our users or the public. In the event of a change of control of Kosmique or any of its subsidiaries (such as through a merger or sale of equity), or in the event of a direct or indirect sale of the app (or any portion thereof) through a sale of assets, liquidation or other transaction, any personal information of users of the affected portion of the app in our possession will be transferred to the new company as part of the transaction. We are not obligated to notify users of any such transaction. You may always change or delete your information by contacting us as instructed in the How To Contact Us section of the Policy, or if the acquirer posts a new privacy policy with new contact information, you may change or delete your information by following any new instructions that are posted.
 
<h2>Your Options</h2>
<p>We offer you certain choices about how we communicate with you and what information we collect from you. To update your preferences or ask us to change or remove your information from our e-mail lists please, please contact us as instructed in the How To Contact Us section of the Policy.
 
<h2>Notice to California Residents</h2>
<p>Subject to certain limits under California Civil Code § 1798.83, California residents may ask us to provide them with (i) a list of certain categories of personal information that we have disclosed to third parties for their direct marketing purposes during the immediately preceding calendar year and (ii) the identity of those third parties. To make this request, California residents may contact us as instructed in the How to Contact Us section of the Policy.
 
<p>California law requires the Policy to address how we respond to any “Do-Not-Track (‘DNT’) signal” delivered by your browser. Because of the changing state of technology and indecision within the industry regarding the meaning of DNT signals, we currently do not make any guarantee that we will honor DNT signals.
 
<h2>Links from and to Third-Party Apps</h2>
<p>The app may have content that is linked from it, and may provide links to, other apps operated by third parties. Any links from or to other apps are provided merely for the convenience of the users of the app. These third-party apps are operated by companies not affiliated with Kosmique, and may have their own privacy policies or notices, which we strongly suggest you review if you visit them. We do not accept, and do disclaim, any responsibility for the privacy policies and information protection practices of any third-party app (whether or not such app is linked on or to the app). These links are provided to you for convenience purposes only, and you access them at your own risk.
 
<h2>How We Protect Personal Information</h2>
<p>We maintain administrative, technical and physical safeguards designed to protect the personal information you provide against accidental, unlawful or unauthorized destruction, loss, alteration, access, disclosure or use. Notwithstanding the aforementioned protections, Kosmique makes no representations, warranties or conditions or any kind, express or implied, with respect thereto.
 
<h2>Children’s Privacy</h2>
<p>This app is directed to persons who are at least thirteen (13) years of age. We do not knowingly collect, use and/or disclose any personally identifiable information on this app from children under the age of 13 years. If a parent or legal guardian is made aware that a child under the age of 13 has provided us with personally identifiable information through our app, we ask to be contacted as instructed in the How to Contact Us section of the Policy and we will delete that information from our records.
 
<h2>How to Contact Us</h2>
<p>If you have any questions about the Policy or the Terms of Use, please contact us at <a href="mailto:info@kosmiqueapp.com">info@kosmiqueapp.com</a>.</p>
`

const PrivacyPolicy = () => (
  <Layout>
    <Seo title="Privacy Policy" />
		<BasicPage title="Privacy Policy" content={content} />
  </Layout>
)

export default PrivacyPolicy
